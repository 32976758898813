<template>
    <div class="input-wrapper p-2 flex flex-col">
        <label :class="[{'sr-only': hide_label},'text-normal text-open mb-2']" :for="the_id">{{label}}</label>
        <input type="text" :ref="the_id" :id="the_id" class=" text-normal text-open px-4 py-1 border" />
    </div>
</template>

<script>
import flatpickr from "flatpickr";

export default {
    name: 'DatePicker',
    data: () => ({
        the_id: false,
        config: {
            enableTime: false,
        },
        picker: false,
    }),
    computed: {
    },
    props: ['hide_label','label','time'],
    methods: {
        emitInput(e) {
            this.$emit('input',e.target.value);
        },
        genId() {
            return Math.random().toString(36).substring(7) + '-' + Math.random().toString(36).substring(7);
        },
    },
    watch: {
    },
    created() {
        if(!this.id) this.the_id = this.genId();
    },
    beforeDestroy() {
    },
    mounted() {
        if(this.time) this.config.enableTime = true
        this.picker = flatpickr(this.$refs[this.the_id], this.config)
    },
    components: {
    },
}
</script>
<style scoped>
</style>
