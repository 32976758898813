<template>
    <div v-click-outside="MaybeClose" :ref="uid" :class="[{'visible':!hide,'invisible':hide,'transform opacity-0 scale-95': !show,'transform opacity-100 scale-100':show},'z-20 absolute transition ease-in duration-75 transition ease-out duration-100 origin-top-right absolute right-0 mt-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none']" role="menu" aria-orientation="vertical" :aria-labelledby="trigger" tabindex="-1">
        <div class="py-1" role="none">
            <!-- Active: "bg-gray-100 text-gray-900", Not Active: "text-gray-700" -->
            <slot></slot>
            <!--<a href="#" class="text-gray-700 block px-4 py-2 text-sm" role="menuitem" tabindex="-1" id="menu-item-0">Account settings</a>-->
        </div>
    </div>
</template>

<script>

export default {
    name: 'Dropdown',
    data: () => ({
        hide:true,
        uid : false,
    }),
    computed: {
    },
    props: ['trigger','show'],
    methods: {
        MaybeClose(evt) {
            if(this.show && evt.target.id != this.trigger && !evt.target.closest('#'+this.trigger)) this.$emit('closed')
        },
        getUid() {
            return Date.now().toString(36) + Math.random().toString(36).substr(2);
        },
    },
    watch: {
        show(val) {
            let that = this
            this.$nextTick(()=>{that.hide = !val})
        },
    },
    created() {
        this.uid = this.getUid()
    },
    beforeDestroy() {
    },
    mounted() {
        let {x,y,height} = document.getElementById(this.trigger).getBoundingClientRect()
        this.$refs[this.uid].style.left = x+'px'
        this.$refs[this.uid].style.top = y+height+'px'
    },
    components: {
    },
}
</script>
<style scoped>  
</style>
