<template>
<!-- This example requires Tailwind CSS v2.0+ -->
<div class="fixed z-10 inset-0 overflow-y-auto" aria-labelledby="modal-title" role="dialog" aria-modal="true">
    <div class="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
        <!--
        Background overlay, show/hide based on modal state.

        Entering: "ease-out duration-300"
            From: "opacity-0"
            To: "opacity-100"
        Leaving: "ease-in duration-200"
            From: "opacity-100"
            To: "opacity-0"
        -->
        <div @click.stop="Close" class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" aria-hidden="true"></div>

        <!-- This element is to trick the browser into centering the modal contents. -->
        <span class="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>

        <!--
        Modal panel, show/hide based on modal state.

        Entering: "ease-out duration-300"
            From: "opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            To: "opacity-100 translate-y-0 sm:scale-100"
        Leaving: "ease-in duration-200"
            From: "opacity-100 translate-y-0 sm:scale-100"
            To: "opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
        -->
        <div class="inline-block align-bottom bg-white rounded-lg text-left overflow-auto shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full">
            <div id="modalcontent" class="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                <div v-if="!modal.component" class="sm:flex sm:items-start">
                    <!--<div class="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
                        <svg class="h-6 w-6 text-red-600" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z" />
                        </svg>
                    </div>
                    -->
                    <div class="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                        <h3 class="text-lg leading-6 font-medium text-gray-900" id="modal-title">
                        {{modal.title}}
                        </h3>
                        <div class="mt-6">
                        <p v-if="!modal.payout" class="text-sm text-gray-500">
                            {{modal.content}}
                        </p>
                        <!--specifically for payout modal-->
                        <div v-else>
                            <h4>Payout for {{modal.item.name}}</h4>
                            <div class="payout-info">
                              <div class="payout-titles">
                                <p>Status:</p>
                                <p>Amount:</p>
                                <p>Paypal Email:</p>
                                <p>Details:</p>
                              </div>
                              <div class="payout-data">
                                <select name="payout_status" id="payout-status" v-model="modal.item.status">
                                  <option value="unpaid">Unpaid</option>
                                  <option value="paid">Paid</option>
                                  <option value="pending">Pending</option>
                                  <option value="cancelled">Cancelled</option>
                                </select>
                                <p>${{modal.item.payout_amount}}</p>
                                <p>{{modal.item.paypal_email}}</p>
                                <textarea v-model="modal.item.details" style="border:1px solid black;"></textarea>
                              </div>
                            </div>

                          <button type="button" style="width:100%;" class="my-6 text-white bg-red font-open text-normal font-semibold px-6 py-1" @click="savePayoutInfo(modal.item)">Save</button>

                          <br>
                            <h5>Transactions:</h5>
                            <table class="transactions-table">
                              <tr>
                                <th>ID</th>
                                <th>Amount</th>
                                <th>Transaction ID</th>
                                <th>Order ID</th>
                              </tr>

                              <tr v-for="(payoutTransaction,i) in this.payoutTransactions">
                                <td>{{payoutTransaction.id}}</td>
                                <td>${{payoutTransaction.amount_commission}}</td>
                                <td>{{payoutTransaction.transaction_id}}</td>
                                <td>{{payoutTransaction.order_id}}</td>
                              </tr>
                            </table>

                        </div>
                        </div>
                    </div>
                </div>
                <div v-else class="sm:flex sm:items-start">
                    <component v-if="modal.component != '' " :is="modal.component" :modaldata="modal.data" />
                </div>
            </div>
            <div id="modalbuttons" class="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
                <ButtonTemplate v-if="modal.showClose" :on_click="Close" class="my-6" type="button">
                   Close
                </ButtonTemplate>
                <ButtonTemplate v-if="modal.action" :on_click="DoAction" class="my-6 mr-2" type="button">
                   Another Action
                </ButtonTemplate>
            </div>
        </div>
    </div>
</div>
</template>

<script>
import Axios from 'axios';
export default {
    name: 'Modal',
    data: () => ({
        componentType: '',
        payoutTransactions: null,
    }),
    computed: {
        modal() {
            return this.$store.state.modal
        },
    },
    props: [],
    methods: {
        Close() {
            this.$store.dispatch('modal/close')
        },
        DoAction() {
            this.$store.state.modal.action()
        },
        savePayoutInfo(info){
          // console.log(info);
          let that = this;
          Axios.post(`/affiliates/update_payout/${this.modal.item.id}`, {
            status: this.modal.item.status,
            details: this.modal.item.details
          }).then(re=>{
            if(re.data && re.data.status == 'OK') {
              // console.log(re.data);
              that.$notify({
                title: 'Payout Updated',
                text: 'Payout info has been saved!',
                type: 'success',
              });
            }
          }).catch(err=>{
            console.log(err);
            if(err.response.status == 403) {
              that.$notify({
                title: 'You are not allowed to do this...',
                text: 'Get lost',
                type: 'error',
              });
            }
          });
        }
    },
    watch: {
    },
    created() {
      if ( this.modal.payout ) {
        let that = this;
        Axios.get(`/affiliates/payout_transactions/${this.modal.item.id}`).then(re=>{
          if(re.data && re.data.status == 'OK') {
            console.log(re.data);
            this.payoutTransactions = re.data.transactions;
          }
        }).catch(err=>{
          console.log(err);
          if(err.response.status == 403) {
            that.$notify({
              title: 'You are not allowed to do this...',
              text: 'Get lost',
              type: 'error',
            });
          }
        });
      }
    },
    beforeDestroy() {
    },
    mounted() {
    },
    components: {
    },
}
</script>
<style scoped>
.payout-info{
  display:grid;
  grid-template-columns: 1fr 1fr;
  grid-gap:15px;
}
.payout-info .payout-titles p{
  font-weight:bold;
}

.transactions-table{
  width:100%;
}
.transactions-table th,td{
  padding:5px;
}
</style>
