<template>
<div>
    <div class="input-wrapper p-2 flex flex-col relative">
        <label class="sr-only" for="main_search">Search</label>
        <div class="absolute top-4 left-4 flex justify-center items-center w-4 h-4">
            <img v-if="searching" class="w-full h-full" src="/assets/loading.gif">
            <img v-else class="w-full h-full" src="/assets/search.png">
        </div>
        <input class='pl-10 bg-lightPurple text-white text-abel py-1 rounded-full' id="main_search" placeholder="Search for menu items" type="text" v-model="search"/>
    </div>
    <div v-if="show_results" class="search-results-container h-3/4 bg-purple p-2 overflow-y-scroll fixed shadow-lg left-0 right-0 flex flex-col">
        <div class="" >
            <ButtonTemplate :on_click="Clean" click_param="close" class="my-2 text-white" type="button">
                Close Search
            </ButtonTemplate>
        </div>
        <div v-for="(group_objects,group_name) in search_results" :key="group_name" class="w-full">
            <h2 class="capitalize text-md text-red">
                {{group_name}}
            </h2>
            <div class="flex-col flex w-full">
                <component :multiple="false" v-bind:is="group_name+'List'" :objects="group_objects" />
            </div>
        </div>
    </div>
</div>
</template>

<script>

export default {
    name: 'Search',
    data: () => ({
        show_results: false,
        search_val: '',
        search_timeout: false,
        searching: false,
        search_results: [],
    }),
    computed: {
        search: {
            get() {
                return this.search_val
            },
            set(val){
                let that = this
                this.search_val = val
                if(this.search_val && this.search_val.trim() != '') {
                    clearTimeout(this.search_timeout)
                    this.search_timeout = setTimeout(()=>{
                        that.doSearch()
                    },500)
                }
            } 
        },
    },
    props: [],
    methods: {
        KeyDown(e) {
            if(e.key === "Escape"){
                this.Clean()
                this.Close()
            }
        },
        Clean(close = false) {
            if(this.search_timeout) clearTimeout(this.search_timeout)
            this.search_results = []
            this.searching = false 
            this.search_val = ''
            if(close) this.Close()
        },
        Close() {
            this.show_results = false
        },
        doSearch() {
            this.searching = true
            this.$requests.post('/search-objects',{
                search_string: this.search_val,
                not: [],
            },
            {
                success: (re) => {
                    this.search_results = re.data.objects
                    this.show_results = true
                    return true
                },
                completed: () => {
                    this.searching = false
                }
            })
        },
    },
    watch: {
        $route() {
            this.Clean()
            this.Close()
        },
    },
    created() {
        document.addEventListener('keydown', this.KeyDown,true)
    },
    beforeDestroy() {
        document.removeEventListener('keydown', this.KeyDown, true)
    },
    mounted() {
    },
    components: {
    },
}
</script>
<style scoped>
</style>
