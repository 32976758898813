import Vue from 'vue';
import Vuex from 'vuex';
import user from './modules/user';
import menu from './modules/menu';
import data from './modules/data';
import modal from './modules/modal';

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    user,
    menu,
    data,
    modal,
  },
});
