<template>
    <div class="input-wrapper p-2 flex flex-col">
        <input @input="emitInput" :id="the_id" type="radio" class="hidden" :name="name" :value="real_val"  v-model="value">
        <label :for="the_id" class="flex items-center cursor-pointer">
            <span class="w-4 h-4 inline-block mr-2 rounded-full border border-grey flex-no-shrink"></span>
            {{label}}
        </label>
    </div>
</template>

<script>

export default {
    name: 'Radio',
    data: () => ({
        the_id: false,
    }),
    computed: {
    },
    props: ["name","label","real_val","value"],
    methods: {
        emitInput(e) {
            this.$emit('input',e.target.value);
        },
        genId() {
            return Math.random().toString(36).substring(7);
        },
    },
    watch: {
    },
    created() {
        if(!this.id) this.the_id = this.genId();
    },
    beforeDestroy() {
    },
    mounted() {
    },
    components: {
    },
}
</script>
<style scoped>
    input[type="radio"] + label span {
            transition: background .2s,
            transform .2s;
    }

    input[type="radio"] + label span:hover,
    input[type="radio"] + label:hover span{
        transform: scale(1.2);
    } 

    input[type="radio"]:checked + label span {
        background-color: #C80A00;
        box-shadow: 0px 0px 0px 2px white inset;
    }

    input[type="radio"]:checked + label{
        color: #C80A00;
    }
</style>
