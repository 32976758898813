import Axios from 'axios';
import Vue from 'vue';
import Swal from 'sweetalert2';

export default {
  data: () => ({
    comments: [],
    edit_comment: false,
    create_comment: {
      active: false,
      content: '',
    },
  }),
  props: ['model'],
  methods: {
    Delete() {
      const that = this;
      Axios.get(`/comments/remove/${that.edit_comment.id}`).then((re) => {
        if (re.data && re.data.status === 'OK') {
          const index = that.comments.findIndex((comment) => comment.id === that.edit_comment.id);
          if (index > -1) Vue.delete(that.comments, index);
          that.edit_comment = false;
          that.$notify({
            title: 'success',
            text: 'Comment was deleted',
            type: 'success',
          });
        } else {
          that.$notify({
            title: 'Something went wrong',
            text: re.data.message,
            type: 'error',
          });
        }
      }).catch((err) => {
        console.error(err);
        if (err.response.status === 403) {
          that.$notify({
            title: 'You are not allowed to do this...',
            text: 'Get lost',
            type: 'error',
          });
        }
      });
    },
    MaybeDelete() {
      const that = this;
      Swal.fire({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, delete it!',
      }).then((result) => {
        if (result.isConfirmed) {
          that.Delete();
        }
      });
    },
    New() {
      this.create_comment.active = true;
    },
    Back() {
      this.edit_comment = false;
      this.create_comment.active = false;
      this.create_comment.content = '';
    },
    Update() {
      const that = this;
      Axios.post('/comment', {
        comment: that.edit_comment,
      }).then(() => {
        that.$notify({
          title: 'Comment updated',
          text: '',
          type: 'success',
        });
        that.Back();
      }).catch((err) => {
        console.error(err);
        if (err.response.status === 403) {
          that.$notify({
            title: 'You are not allowed to do this...',
            text: 'Get lost',
            type: 'error',
          });
        }
      });
    },
    Save() {
      const that = this;
      Axios.post('/comment/create', {
        comment: that.create_comment,
        model: that.model,
      }).then((re) => {
        that.comments.push(re.data.item);
        that.Back();
      }).catch((err) => {
        console.error(err);
        if (err.response.status === 403) {
          that.$notify({
            title: 'You are not allowed to do this...',
            text: 'Get lost',
            type: 'error',
          });
        }
      });
    },
    Edit(comment) {
      this.edit_comment = comment;
    },
    getComments() {
      const that = this;
      Axios.post('/comments', {
        id: that.model.id,
        model: that.model.type,
      }).then((re) => {
        Vue.set(that, 'comments', re.data.items);
      }).catch((err) => {
        console.error(err);
        if (err.response.status === 403) {
          that.$notify({
            title: 'You are not allowed to do this...',
            text: 'Get lost',
            type: 'error',
          });
        }
      });
    },
  },
  created() {
    this.getComments();
  },
};
