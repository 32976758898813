<template>
    <button :disabled="disabled" @click="Clicked" :type="type" :class="classList">

        <slot></slot>

    </button>
</template>

<script>

export default {
    name: 'Button',
    data: () => ({
    }),
    computed: {
        classList() {
            let classes = {
                'text-white': true,
                'bg-red': true,
                'font-open': true,
                'text-normal':true,
                'font-semibold':true,
                'px-6': true,
                'py-1': true,
            }
            if(this.btn_style == 'secondary') {
                classes['text-white'] = false;
                classes['text-black'] = true;
                classes['bg-red'] = false;
                classes['bg-yellow'] = true;
            }
            if(this.btn_style == 'secondary-white') {
                classes['bg-red'] = false;
                classes['bg-yellow'] = true;

            }
            if(this.btn_style == 'light') {
                classes['text-white'] = false;
                classes['text-red'] = true;
                classes['bg-red'] = false;
                classes['bg-white'] = true;
                classes['border'] = true;
                classes['border-red'] = true;
            }
            if(this.btn_style == 'link') {
                classes['text-white'] = false;
                classes['text-red'] = true;
                classes['bg-red'] = false;
                classes['bg-white'] = true;
                classes['border'] = false;
                classes['border-red'] = false;
                classes['underline'] = true;
            }
            return classes;
        },
    },
    props: ['type','btn_style','on_click','click_param','disabled'],
    methods: {
        Clicked() {
            if(this.on_click) {
                this.on_click(this.click_param);
            }
        },
    },
    watch: {
    },
    created() {
    },
    beforeDestroy() {
    },
    mounted() {
    },
    components: {
    },
}
</script>
<style scoped>
</style>
