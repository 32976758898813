<template>
<div class="flex-grow">
    <div v-if="submitting" class="my-6">
            <img style="width:50px;height:50px;" src="/assets/loading.gif" alt="Loading form submission">
    </div>
    <div v-else>
        <TextInput class="w-full" type="text" label="Title*" v-model="data.title" :required="true" ></TextInput>
        <div class="flex">
            <TextInput class="w-1/2" type="text" label="Level*" v-model="data.level" :required="true" ></TextInput>
            <TextInput class="w-1/2" type="text" label="Number*" v-model="data.number" :required="true" ></TextInput>
        </div>
            <TextInput class="w-full" type="textarea" label="Description*" v-model="data.description" :required="true" ></TextInput>
            <TextInput class="w-full" type="text" label="Internal URL*" v-model="data.url" :required="true" ></TextInput>
            <TextInput class="w-full" type="text" label="External URL*" v-model="data.ext_url" :required="true" ></TextInput>
            <ButtonTemplate :on_click="Save" class="my-6 mr-2" type="button" >Save</ButtonTemplate>
            <ButtonTemplate :on_click="Delete" class="my-6 mr-2" type="button" >Delete</ButtonTemplate>
        </div>
</div>

</template>

<script>

export default {
    name: 'SuccessCriteria',
    data: () => ({
        data: {},

        submitting: false,
    }),
    props: ['modaldata'],
    methods: {
        Save()
        {
            this.submitting = true
            this.$requests.post(`/globalArticles/update`,
            {
                data: this.data,
            },
            {

                success: (re) => {
                    console.log(re.data);
                    return false;
                },
                completed: (re) => {
                    this.submitting = false
                    return false;
                }
            })
        },
        Delete()
        {
            this.submitting = true
            this.$requests.post(`/globalArticles/delete`,
            {
                id: this.data.id,
            },
            {

                success: (re) => {
                    this.$store.dispatch('modal/close');
                    return true;
                },
                completed: (re) => {
                    this.submitting = false
                    return true;
                }
            })
        },
    },
    watch: {
    },
    created() {
        if ( this.modaldata == -1 )
        {
            this.data.id = -1;
            this.data.title = "";
            this.data.level = "";
            this.data.number = "";
            this.data.description = "";
            this.data.url = "";
            this.data.ext_url = "";
        }
        else
            this.data = this.modaldata;
    },
    beforeDestroy() {
    },
    mounted() {
    },
    components: {
    },
}
</script>
<style scoped>
</style>
