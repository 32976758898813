import Vue from 'vue';
import CustomersList from './Customers.vue';
import TicketsList from './Tickets.vue';
import CallsList from './Calls.vue';
import AgenciesList from './Agencies.vue';
import LicensesList from './Licenses.vue';
import NotesList from './Notes.vue';
import CommentsList from './Comments.vue';

Vue.component('CustomersList', CustomersList);
Vue.component('TicketsList', TicketsList);
Vue.component('CallsList', CallsList);
Vue.component('AgenciesList', AgenciesList);
Vue.component('LicensesList', LicensesList);
Vue.component('NotesList', NotesList);
Vue.component('CommentsList', CommentsList);
