<template>
<div :class="'slideout-wrapper overflow-y-scroll z-20 bg-white text-purple '+width+' mt-16 pt-4 shadow-2xl '+position+' '+orientation">
    <div v-if="expanded != 'full'" class="">
        <button :title="content.label" v-for="(content,i) in contents" :key="i" @click="ToggleState(content)" :class="'z-9 fixed rounded-l-lg toggle-button bg-purple text-white p-2 flex justify-center items-center'" :style="'top: '+content.top+';'" >
            <img :src="content.icon">
        </button>
    </div>
    <div v-else class="w-full flex pl-6 border-b border-purple">
        <button :title="content.label" v-for="(content,i) in contents" :key="i" @click="ToggleState(content)" :class="'mx-2 z-9 rounded-t-lg toggle-button full bg-purple text-white p-2 flex justify-center items-center'" >
            <img :src="content.icon">
        </button>
    </div>
    <button @click="Close" :class="[{'hidden':!content},'fixed right-0 top-16 w-6 h-6 rounded-full text-white bg-red']">
        X
    </button>
    <div v-if="content" class="p-6">
        <keep-alive>
            <component v-bind:is="content.component" :model="model"></component>
        </keep-alive>
    </div>
</div>

</template>

<script>
import Vue from 'vue'
export default {
    name: 'Slideout',
    data: () => ({
        expanded: 'not-visible',
        content: false,
        content_width: 'quarter',
    }),
    computed: {
        width() {
            if(this.expanded == 'full') return 'w-full' 
            if(this.expanded == 'half') return 'w-1/2' 
            if(this.expanded == 'quarters') return 'w-3/4' 
            if(this.expanded == 'quarter') return 'w-1/4' 
            return 'not-visible'
        },
    },
    props: ['default_width','contents','model','position','orientation'],
    methods: {
        KeyDown(e) {
            if(e.key === "Escape"){
                this.Close()
            }
        },
        Close() {
            Vue.set(this,'expanded','not-visible')
            Vue.set(this,'content',false)
        },
        ToggleState(content) {
            if(this.expanded == 'not-visible' || this.content.label != content.label) {
                Vue.set(this,'content',content)
                this.expanded = this.content_width
            }
        }
    },
    watch: {
        $route(val) {
            this.Close()
        },
    },
    created() {
        if(this.default_width) this.content_width = this.default_width
        document.addEventListener('keydown', this.KeyDown,true)
        let screenWidth = (window.innerWidth > 0) ? window.innerWidth : screen.width
        if(screenWidth <= 1025) {
            this.content_width = 'full'
        }

        for ( let i in this.contents )
        {
            if (  this.contents[i].label == location.hash.substring(1) )
            {
                this.ToggleState(this.contents[i]);
                break;
            }
        }
    },
    beforeDestroy() {
        document.removeEventListener('keydown', this.KeyDown, true)
    },
    mounted() {
    },
    components: {
    },
}
</script>
<style scoped>
.slideout-wrapper{
    transition: width .2s ease-in-out;
}
.slideout-wrapper.not-visible {
    width: 0px;
}
.toggle-button {
    width: 52px;
    height: 52px;
    margin-left: -52px;
}
.toggle-button.full {
    width: 52px;
    height: 52px;
    margin-left: 0px;
}
.toggle-button img {
    filter: invert(1);
}
</style>
