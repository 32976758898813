<template>
<div class="my-2">
    <h2 class="text-red text-md">Notes</h2>
    <div v-if="create_note.active" class="mt-4">
        <div class="border border-red my-2 shadow-lg p-6">
            <ButtonTemplate :on_click="Back" class="my-2" type="button">
                Back
            </ButtonTemplate>
            <div class="h-full w-full overflow-auto	">
                <wysiwyg v-model="create_note.content" />
            </div>
            <ButtonTemplate :on_click="Save" class="my-2" type="button">
                Save
            </ButtonTemplate>
        </div>
    </div>
    <div v-if="edit_note" class="mt-4">
        <div class="border border-red my-2 shadow-lg p-6">
            <ButtonTemplate :on_click="Back" class="my-2" type="button">
                Back
            </ButtonTemplate>
            <div class="h-full w-full overflow-auto	">
                <wysiwyg v-model="edit_note.content" />
            </div>
            <ButtonTemplate :on_click="Update" class="my-2" type="button">
                Save
            </ButtonTemplate>
            <ButtonTemplate btn_style="light" :on_click="MaybeDelete" class="my-2 ml-10" type="button">
                Delete
            </ButtonTemplate>
        </div>
    </div>
    <div v-if="!edit_note && !create_note.active" class="mt-4">
        <ButtonTemplate :on_click="New" class="my-2" type="button">
            Add Note
        </ButtonTemplate>
        <div class="border border-red my-2 shadow-lg p-6" v-for="(note,i) in notes" :key="i">
            <div class="h-full w-full overflow-auto	" v-html="note.content"></div>
            <div v-if="note.by" class="h-full w-full">
                <small>
                    Created: {{getDate(note.created_at)}} By {{note.by.email}}
                </small>
            </div>
            <ButtonTemplate :on_click="Edit" :click_param="note" class="my-2" type="button">
                Edit
            </ButtonTemplate>
        </div>
    </div>
</div>
</template>

<script>
import Mixin from '../../mixins/Notes'
import moment from 'moment'
export default {
    name: 'Notes',
    data: () => ({
    }),
    computed: {
    },
    props: [],
    mixins: [Mixin],
    methods: {
        getDate(datetime) {
            return moment(datetime).format('MMMM Do YYYY');
        },
    },
    watch: {
    },
    created() {
    },
    beforeDestroy() {
    },
    mounted() {
    },
    components: {
    },
}
</script>
<style scoped>
</style>
