export default {
  data: () => ({
    field_data: false,
    FieldTypes: [
      {
        label: 'Repeater Field',
        value: 'RepeaterField',
      },
      {
        label: 'Text Field',
        value: 'TextField',
      },
    ],
  }),
  props: ['field'],
  computed: {
    field_type() {
      return this.field_data.type;
    },
  },
  watch: {
    field_type(val) {
      if (val === 'RepeaterField' && (!this.field_data.value || !Array.isArray(this.field_data.value))) {
        this.field_data.value = [];
      }
    },
  },
  methods: {
  },
  created() {
    this.field_data = this.field;
  },
};
