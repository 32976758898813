<template>
<div class="flex flex-col p-2 w-full" >
    <div class="text-md">
        <TextInput type="text" label="Field Name" v-model="field_data.name" ></TextInput>
    </div>
    <div class="text-md">
        <SelectInput label="Field Type" v-model="field_data.type" :options="FieldTypes"></SelectInput>
    </div>
    <div class="p-2 flex flex-col">
        <div>Fields</div>
        <ButtonTemplate :on_click="AddNewField" type="button" btn_style="secondary" class="" >
            New Field
        </ButtonTemplate>
        <div v-if="CustomFields" class="flex flex-wrap w-full">
            <div v-for="(custom_field,i) in field_data.value" :key="i" class="w-full">
                <component :is="CustomFields[custom_field.type]" :field="custom_field" />
            </div>
        </div>       
    </div>
</div>
</template>

<script>
import Fields from './'
import { v4 as uuidv4 } from 'uuid';
import Mixin from './mixin'
export default {
    name: 'Repeater',
    data: () => ({
        CustomFields: false,
    }),
    computed: {
    },
    mixins: [Mixin],
    methods: {
        AddNewField() {
            this.field_data.value.push({
                id: uuidv4(),
                type:'TextField',
                name:'',
                value: '',
            })
        },
    },
    created() {
        this.CustomFields = Fields
    },
}
</script>
<style scoped>
</style>
