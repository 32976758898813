<template>
<div>
    <TableTemplate @item:checked="ItemChecked" @item:unchecked="ItemUnchecked" :dark="false" :multiselect="multiple" :candelete="false" canview="/comments/" :columns="columns" :items="objects"></TableTemplate>
</div>
</template>

<script>
export default {
    name: 'CommentsList',
    data: () => ({
        columns:[
            {
                id:'id',
                label:'ID',
            },
            {
                id:'user',
                label:'Author',
                custom: 'DisplayAuthor'
            },
            {
                id:'content',
                label:'Content',
                custom: 'DisplayContent'
            },
        ],
    }),
    computed: {
    },
    props: ['objects','multiple'],
    methods: {
        DisplayContent(item) {
            return item.content.substring(0,255)
        },
        DisplayAuthor(item) {
            if(item.created_by) return item.created_by.email
            return ''
        },
        ItemChecked(item) {
            this.$emit('item:selected',item)
        },
        ItemUnchecked(item) {
            this.$emit('item:removed',item)
        },
    },
    watch: {
    },
    created() {
    },
    beforeDestroy() {
    },
    mounted() {
    },
    components: {
    },
}
</script>
<style scoped>
</style>
