<template>
    <div class="input-wrapper p-2 flex flex-col">
        <label class="text-normal text-open mb-2" :for="the_id">{{label}}</label>
        <select class="text-normal text-open px-4 py-1 border" @input="emitInput" :id="the_id" :value="value" :required="required" :disabled="disabled" :multiple="multiple">
            <option v-for="(option,i) in options" :key="i" :value="option.value">
                {{option.label}}
            </option>
        </select>
    </div>
</template>

<script>

export default {
    name: 'Select',
    data: () => ({
        the_id: false,
    }),
    computed: {
    },
    props: ['disabled','id','label','required','options','value','multiple'],
    methods: {
        emitInput(e) {
            this.$emit('input',e.target.value);
        },
        genId() {
            return Math.random().toString(36).substring(7);
        },
    },
    watch: {
    },
    created() {
        if(!this.id) this.the_id = this.genId();
    },
    beforeDestroy() {
    },
    mounted() {
    },
    components: {
    },
}
</script>
<style scoped>
</style>
