import Axios from 'axios';
import Vue from 'vue';
import Swal from 'sweetalert2';

export default {
  data: () => ({
    notes: [],
    edit_note: false,
    create_note: {
      active: false,
      content: '',
    },
  }),
  props: ['model'],
  methods: {
    Delete() {
      const that = this;
      Axios.get(`/notes/remove/${that.edit_note.id}`).then((re) => {
        if (re.data && re.data.status === 'OK') {
          const index = that.notes.findIndex((note) => note.id === that.edit_note.id);
          if (index > -1) Vue.delete(that.notes, index);
          that.edit_note = false;
          that.$notify({
            title: 'success',
            text: 'Note was deleted',
            type: 'success',
          });
        } else {
          that.$notify({
            title: 'Something went wrong',
            text: re.data.message,
            type: 'error',
          });
        }
      }).catch((err) => {
        console.error(err);
        if (err.response.status === 403) {
          that.$notify({
            title: 'You are not allowed to do this...',
            text: 'Get lost',
            type: 'error',
          });
        }
      });
    },
    MaybeDelete() {
      const that = this;
      Swal.fire({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, delete it!',
      }).then((result) => {
        if (result.isConfirmed) {
          that.Delete();
        }
      });
    },
    New() {
      this.create_note.active = true;
    },
    Back() {
      this.edit_note = false;
      this.create_note.active = false;
      this.create_note.content = '';
    },
    Update() {
      const that = this;
      Axios.post('/note', {
        note: that.edit_note,
      }).then(() => {
        that.$notify({
          title: 'Note updated',
          text: '',
          type: 'success',
        });
        that.Back();
      }).catch((err) => {
        console.error(err);
        if (err.response.status === 403) {
          that.$notify({
            title: 'You are not allowed to do this...',
            text: 'Get lost',
            type: 'error',
          });
        }
      });
    },
    Save() {
      const that = this;
      Axios.post('/note/create', {
        note: that.create_note,
        model: that.model,
      }).then((re) => {
        that.notes.push(re.data.item);
        that.Back();
      }).catch((err) => {
        console.error(err);
        if (err.response.status === 403) {
          that.$notify({
            title: 'You are not allowed to do this...',
            text: 'Get lost',
            type: 'error',
          });
        }
      });
    },
    Edit(note) {
      this.edit_note = note;
    },
    getNotes() {
      const that = this;
      Axios.post('/notes', {
        id: that.model.id,
        model: that.model.type,
      }).then((re) => {
        Vue.set(that, 'notes', re.data.items);
      }).catch((err) => {
        console.error(err);
        if (err.response.status === 403) {
          that.$notify({
            title: 'You are not allowed to do this...',
            text: 'Get lost',
            type: 'error',
          });
        }
      });
    },
  },
  created() {
    this.getNotes();
  },
};
