<template>
<div v-if="attachments" id="AttachmentsContainer" ref="AttachmentsContainer" class="my-2 attachments-wrapper">
    <h2 class="my-2 text-red text-md">Attachments</h2>
    <div v-if="!hide_add" class="flex flex-col my-2 attachments-dropzone">
        <label for="AttachmentsFile" >Upload Attachments</label>
        <input :disabled="uploading" type="file" id="AttachmentsFile" ref="AttachmentsFile" @change="FileUpload" multiple/>
    </div>
    <div class="flex flex-col my-4" >
        <div class="flex">
            <ButtonTemplate v-if="selected_attachments.length" :on_click="DeleteSelectedAttachments" class="w-1/2" type="button">
                Delete Selected
            </ButtonTemplate>
            <ButtonTemplate :disabled="true" v-else class="w-1/2" type="button">
                Delete Selected
            </ButtonTemplate>
        </div>
    </div>
    <div v-if="uploading" class="" >
          Files are uploading<i class="fas fa-circle-notch fa-spin"></i>
    </div>
    <div v-if="!uploading" class="my-2 flex flex-wrap" >
        <div v-for="(attachment,i) in attachments" :key="i" class="m-4 w-1/4 image-thumbnail relative">
            <img :id="attachment.id+'-attachment'" v-if="!thumb_map[attachment.type]" style="width:100%;max-height:200px;" :src="attachment.temp_url" @click="OpenFull(attachment)" role="button"/>
            <img :id="attachment.id+'-attachment'" v-else style="width:200px;height:200px;" :src="thumb_map[attachment.type]" @click="OpenFull(attachment)" role="button"/>
            <TextInput :hide_label="true" type="text" label="Title" :required="false" v-model="attachment.title" @input="MaybyUpdateTitle(attachment)"></TextInput>
            <button @click="AttachmentChecked(attachment)" class="absolute" style="top:-30px;right:-30px;">
                <img v-if="selected_attachments.indexOf(attachment.id) > -1" style="width:25px;height:25px;" src="/assets/check-circle-filled.png" />
                <img v-else style="width:25px;height:25px;" src="/assets/circle-empty.png" />
            </button>
        </div>
    </div>
</div>
</template>

<script>
import Axios from 'axios'
import Vue from 'vue'
export default {
    name: 'Attachments',
    data: () => ({
        attachments: [],
        uploading: false,
        full: false,
        thumb_map: {
            'pdf' : '/assets/pdf.png',
        },
        selected_attachments: [],
        timeouts: {},
    }),
    computed: {
    },
    props: ['model','value','hide_add'],
    methods: {
        MaybyUpdateTitle(attachment) {
            let that = this
            if(this.timeouts[attachment.id]) clearTimeout(this.timeouts[attachment.id])
            Vue.set(this.timeouts,attachment.id,setTimeout(()=>{
                that.UpdateTitle(attachment);
            },500))
        },
        UpdateTitle(attachment) {
            let that = this
            Axios.post('/attachment',{
                attachment: attachment,
            }).then(()=>{
                that.$notify({
                    title: 'Title updated',
                    text: '',
                    type: 'success',
                });
            }).catch(err=>{
                if(err.response && err.response.status == 403) {
                    that.$notify({
                        title: 'You are not allowed to do this...',
                        text: 'Get lost',
                        type: 'error',
                    });
                }
            });
        },
        DeleteSelectedAttachments() {
            for(let i in this.selected_attachments) {
                this.DeleteAttachment(this.selected_attachments[i])
            }
        },
        AttachmentChecked(attachment) {
            let index = this.selected_attachments.indexOf(attachment.id)
            if(index > -1) {
                this.selected_attachments.splice(index,1)
            }else{
                this.selected_attachments.push(attachment.id)
            }
        },
        DeleteAttachment(attachment_id) {
            let that = this
            Axios.get('/attachments/remove/'+attachment_id)
            .then(re=>{
                let index = that.attachments.findIndex((object)=>{return object.id == attachment_id})
                if(index > -1) that.attachments.splice(index,1)
                that.full = false
                that.$notify({
                    title: 'Attachment deleted successfully.',
                    text: '',
                    type: 'success',
                });
            }).catch(err=>{
                if(err.response.status == 403) {
                    that.$notify({
                        title: 'You are not allowed to do this...',
                        text: 'Get lost',
                        type: 'error',
                    });
                }
            });
        },
        getAttachments() {
            if(!this.model) return;
            let that = this
            Axios.post('/attachments',{
                model: that.model.type,
                item_id: that.model.id,
            }).then(re=>{
                Vue.set(that,'attachments',re.data.items)
            }).catch(err=>{
                if(err.response.status == 403) {
                    that.$notify({
                        title: 'You are not allowed to do this...',
                        text: 'Get lost',
                        type: 'error',
                    });
                }
            });
        },
        OpenFull(attachment) {
            window.open(attachment.temp_url,'_blank')
        },
        FileUpload() {
            let target_input = this.$refs['AttachmentsFile']
            if(!target_input.files.length) return;
            let that = this
            for(let i in target_input.files) {
                if(typeof target_input.files[i] == 'object'){
                    that.uploading = true
                    let formData = new FormData(); 
                    formData.append('attachment', target_input.files[i])
                    if(that.model.id) {
                        formData.append('attach',JSON.stringify({model:that.model.type,item_id:that.model.id}))
                    }
                    Axios.post( '/attachment/create',
                        formData,
                        {
                            headers: {
                                'Content-Type': 'multipart/form-data'
                            }
                        }
                    ).then(function(re){
                        that.uploading = false
                        that.attachments.push(re.data.attachment)
                        that.$notify({
                            title: 'Attachment uploaded',
                            text:  re.data.attachment.path,
                            type: 'success',
                        });
                    })
                    .catch(function(err){
                        that.uploading = false
                        console.log(err);
                    });
                }
            }

        },
    },
    watch: {
        attachments: {
            handler(val) {
                this.$emit('input',val);
            },
            deep:true,
        },
    },
    created() {
        this.getAttachments()
    },
    beforeDestroy() {
    },
    mounted() {
    },
    components: {
    },
}
</script>
<style>
</style>
