<template>
    <div class="input-wrapper p-2 flex flex-col relative">
        <label :class="[{'sr-only': hide_label},'text-normal text-open mb-2']" :for="the_id">{{label}}</label>
        <div v-if="icon_before" class="absolute" style="left:15px;bottom:15px;width:20px;height:20px;">
            <img :src="icon_before" />
        </div>
        <div class="w-full" v-if="keyup">
            <input @keyup.enter.prevent.stop="keyup" :step="step" :min="min" :max="max" @input="emitInput" :class="[{'pl-10': icon_before},'w-full text-normal text-open px-4 py-1 border']" :id="the_id" :name="name" :placeholder="placeholder" :type="type" :disabled="disabled" :required="required" :value="value"/>
        </div>
        <div class="w-full" v-else>
            <input :step="step" :min="min" :max="max" @input="emitInput" :class="[{'pl-10': icon_before},'w-full text-normal text-open px-4 py-1 border']" :id="the_id" :name="name" :placeholder="placeholder" :type="type" :disabled="disabled" :required="required" :value="value"/>
        </div>
    </div>
</template>

<script>

export default {
    name: 'TextInput',
    data: () => ({
        the_id: false,
    }),
    computed: {
    },
    props: ['keyup','step','icon_before','hide_label','disabled','label','value','placeholder','name','id','required','type','min','max'],
    methods: {
        emitInput(e) {
            this.$emit('input',e.target.value);
        },
        genId() {
            return Math.random().toString(36).substring(7);
        },
    },
    watch: {
    },
    created() {
        if(!this.id) this.the_id = this.genId();
    },
    beforeDestroy() {
    },
    mounted() {
    },
    components: {
    },
}
</script>
<style scoped>
</style>
