<template>
<form onkeydown="return event.key != 'Enter';" @submit="checkform">
    <div :class="[{'flex items-end':inline},'w-full h-full']">
        <slot></slot>
        <ButtonTemplate v-if="button && !submitting" class="my-6" type="submit">
            {{button}}
        </ButtonTemplate>
        <ButtonTemplate v-if="submitting" class="my-6" :disabled="true" type="button">
            <img style="width:50px;height:50px;" src="/assets/loading.gif" alt="Loading form submission">
        </ButtonTemplate>
    </div>
    <div v-if="messages.length" class="p-6 my-10">
        <p v-for="(message,i) in messages" :key="i" class="bg-red text-white">
            {{message}}
        </p>
    </div>
</form>
</template>

<script>
import Axios from 'axios'
export default {
    name: 'Form',
    data: () => ({
        submitting: false,
        messages: [],
    }),
    computed: {
    },
    props: ['button','action','method','formdata','inline'],
    methods: {
        checkform(e) {
            e.preventDefault();
            this.submit();
        },
        errorMessages(messages) {
            for(let i in messages) {
                this.messages  = this.messages.concat(messages[i])
            }
        },
        validationMessages(messages) {
            for(let i in messages) {
                this.messages  = this.messages.concat(messages[i])
            }
        },
        submit() {
            let that = this;
            that.submitting = true
            that.messages = [];
            Axios[this.method](this.action,this.formdata).then(re => {
                if(re && re.data && re.data.status === 'VALIDATION') that.validationMessages(re.data.messages);
                if(re && re.data && re.data.status === 'ERROR') that.errorMessages(re.data.messages);
                that.$emit('response',re)
            }).catch(err => {
                console.log(err);
                if(err.response.status == 403) {
                    that.$notify({
                        title: 'You are not allowed to do this...',
                        text: 'Get lost',
                        type: 'error',
                    });
                }else{
                    that.$notify({
                        title: 'Something went wrong',
                        text: err.message,
                        type: 'error',
                    });
                }
            }).then(() => {
                that.submitting = false
            })
        },
    },
    watch: {
    },
    created() {
    },
    beforeDestroy() {
    },
    mounted() {
    },
    components: {
    },
}
</script>
<style scoped>
</style>
