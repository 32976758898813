import Vue from 'vue';
import Axios from 'axios';

export default class {
  get(url, callbacks) {
    Axios.get(url).then((re) => {
      if (re.data && re.data.status === 'OK') {
        if (!callbacks.success(re)) {
          Vue.notify({
            title: 'Success. Request complete',
            text: '',
            type: 'success',
          });
        }
      } else if (re.data && re.data.status === 'NOTOK') {
        if (!callbacks.notok || !callbacks.notok(re)) {
          Vue.notify({
            title: 'Something went wrong',
            text: re.data.message,
            type: 'error',
          });
        }
      }
    }).catch((err) => {
      if (!callbacks.failure || !callbacks.failure()) {
        Vue.notify({
          title: 'Whoops! Something went wrong',
          text: err.message,
          type: 'error',
        });
      }
      console.error(err);
    }).then(() => {
      if (!callbacks.completed || !callbacks.completed()) {
        console.log('request completed');
      }
    });
  }

  post(url, formData, callbacks) {
    Axios.post(url, formData).then((re) => {
      if (re.data && re.data.status === 'OK') {
        if (!callbacks.success(re)) {
          Vue.notify({
            title: 'Success. Request complete',
            text: '',
            type: 'success',
          });
        }
      }
    }).catch((err) => {
      if (!callbacks.failure || !callbacks.failure()) {
        Vue.notify({
          title: 'Whoops! Something went wrong',
          text: err.message,
          type: 'error',
        });
      }
      console.error(err);
    }).then(() => {
      // eslint-disable-next-line
      if (!callbacks.completed || !callbacks.completed()) {

      }
    });
  }
}
