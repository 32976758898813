import Vue from 'vue';

const getDefaultState = () => ({
  open: false,
  component: false,
  title: false,
  content: false,
  data: false,
  showClose: true,
  action: false,
  payout: false,
  item: null
});
export default {
  namespaced: true,
  state: {
    open: false,
    component: false,
    title: false,
    content: false,
    data: false,
    showClose: true,
    action: false,
    payout:false,
    item: null
  },
  mutations: {
    setState(state, payload) {
      Vue.set(state, payload.key, payload.value);
    },
    resetState(state) {
      Object.assign(state, getDefaultState());
    },
    setContent(state, payload) {
      if (payload.component) state.component = payload.component;
      if (payload.title) state.title = payload.title;
      if (payload.content) state.content = payload.content;
      if (payload.action) state.action = payload.action;
      if (payload.open) state.open = true;
      if (payload.data) state.data = payload.data;
      if (payload.payout) state.payout = true;
      if (payload.item) state.item = payload.item;
    },
  },
  actions: {
    resetState({ commit }) {
      commit('resetState');
    },
    open({ state }) {
      state.open = true;
    },
    close({ commit }) {
      commit('resetState');
    },
  },
  getters: {
  },
};
