<template>
<div class="flex flex-col flex-wrap my-4">
    <div class="w-full flex items-center">
        <h2 class="text-lg">Custom Fields</h2>
        <ButtonTemplate :on_click="AddNewField" type="button" btn_style="secondary" class="h-10" >
            New Field
        </ButtonTemplate>
    </div>
    <div v-if="CustomFields" class="flex flex-wrap w-full ">
        <div v-for="(custom_field,i) in custom_fields" :key="i" class="w-full">
            <component :is="CustomFields[custom_field.type]" :field="custom_field" />
        </div>
    </div>
</div>
</template>

<script>
import Fields from './fields'
import { v4 as uuidv4 } from 'uuid';
export default {
    name: 'CustomFields',
    data: () => ({
        custom_fields: [],
        CustomFields: false,
    }),
    computed: {
    },
    props: ['fields'],
    methods: {
        AddNewField() {
            this.custom_fields.push({
                id: uuidv4(),
                type:'TextField',
                name:'',
                value: '',
            })
        },
    },
    watch: {
    },
    created() {
        this.CustomFields = Fields
        this.custom_fields = this.fields
    },
    beforeDestroy() {
    },
    mounted() {
    },
}
</script>
<style scoped>
</style>
