<template>
<div class="flex-grow">
    <div v-if="submitting" class="my-6">
            <img style="width:50px;height:50px;" src="/assets/loading.gif" alt="Loading form submission">
    </div>
    <div v-else>
        <SearchSelectInput v-if="data.id != -1" class="w-full" @item:removed="articleRemoved" @item:selected="articleSelected" :params="articleSearchParams" :multiple="true" label="Add Article" v-model="articles" :optiondisplay="articleDisplay" :hideSelected="false"></SearchSelectInput>

        <TextInput class="w-full" type="textarea" label="Description*" v-model="data.description" :required="true" ></TextInput>
        <ButtonTemplate :on_click="Save" class="my-6 mr-2" type="button" >Save</ButtonTemplate>
        <ButtonTemplate :on_click="Delete" class="my-6 mr-2" type="button" >Delete</ButtonTemplate>
    </div>
</div>

</template>

<script>

export default {
    name: 'Recommendations',
    data: () => ({
        data: {},
        articles: [],
        article: false,  
        search: [],
        articleSearchParams: {
            'model': 'Article',
            'fields': ['id','title','description'],
            'action': '/search/',
        },

        submitting: false,
    }),
    props: ['modaldata'],
    methods: {
        Save()
        {
            this.submitting = true
            this.$requests.post(`/globalRecommendations/update`,
            {
                data: this.data,
            },
            {

                success: (re) => {
                    console.log(re.data);
                    return true;
                },
                completed: (re) => {
                    this.submitting = false
                    return true;
                }
            })
        },
        Delete()
        {
            this.submitting = true
            this.$requests.post(`/globalRecommendations/delete`,
            {
                id: this.data.id,
            },
            {

                success: (re) => {
                    this.$store.dispatch('modal/close');
                    return true;
                },
                completed: (re) => {
                    this.submitting = false
                    return true;
                }
            })
        },

        articleDisplay(item) {
            return 'ID: ' + item.id + ' Title: ' + item.title;
        },
        articleSelected(evt) {
            let that = this
            this.submitting = true
            this.$requests.post('/globalRecommendations/assign',{
                model_id: evt.item.id,
                model: 'Article',
                recommendation_id: that.data.id
            },
            {
                success: () => {
                    let index = that.articles.findIndex((article)=>{return article.id == evt.item.id})
                    if(index < 0) that.articles.push(evt.item);
                    return true
                },
                completed: (re) => {
                    that.submitting = false
                    return true;
                }
            })
        },
        articleRemoved(evt) {
            this.articles.splice(evt.index,1);

            this.submitting = true
            let that = this
            this.$requests.post('/globalRecommendations/removeArticle',{
                model_id: evt.item.id,
                model: 'Article',
                recommendation_id: that.data.id
            },
            {
                success: () => {
                    console.log("Success!");
                    return true;
                },
                completed: (re) => {
                    that.submitting = false
                    return true;
                }
            })
        },
    },
    watch: {
    },
    created() {
        if ( this.modaldata == -1 )
        {
            this.data.id = -1;
            this.data.description = "";
        }
        else
        {
            this.data = this.modaldata;
            this.submitting = true
            let that = this;
            this.$requests.post(`/globalRecommendations/articles`, {
                id: that.data.id
            },
            {
                success: (re) => {
                    that.articles = re.data.items
                    return true;
                },
                completed: (re) => {
                    that.submitting = false
                    return true;
                }
            });
        }
    },
    beforeDestroy() {
    },
    mounted() {
    },
    components: {
    },
}
</script>
<style scoped>
</style>
