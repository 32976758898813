<template>
    <div class="relative input-wrapper p-2 flex flex-col">
        <label :class="[{'sr-only':hide_label},'text-normal text-open mb-2']" :for="the_id">{{label}}</label>
        <input @focus="hide = false" placeholder="Search..." @input="maybeSearch" v-model="search" class="text-normal text-open px-4 py-4 border-l border-r border-t"/>
        <button v-if="items.length && !loading && !hide" type="button" @click="CloseSearch" class="p-2 bg-red text-white font-work absolute right-2 top-10">Close</button>
        <button v-if="can_create && !loading && !hide" type="button" @click="CreateItem" class="p-2 bg-red text-white font-work absolute right-16 top-10">Create</button>
        <div v-if="loading" class="aboslute top-0 right-0">
            <i class="fas fa-circle-notch fa-spin"></i>
        </div>
        <div v-if="multiple" class="px-4 py-1 border-l border-r border-b flex flex-wrap">
            <span class="flex flex-wrap" v-if="optiondisplay && !hideSelected" >
                <div v-for="(item,i) in value" :key="i" class="p-2 bg-yellow mx-2 my-1">
                    <span role="button" @click="ItemClick(item)" v-html="optiondisplay(item)">
                    </span>
                    <button title="Remove Item" type="button" @click="removeItem(item,i)" class="ml-5 text-red font-bold">
                        X
                    </button>
                </div>
            </span>
        </div>
        <div v-else class="px-4 py-1 border-l border-r border-b flex flex-wrap">
            <span v-if="optiondisplay && !hideSelected" >
                <div v-if="value" class="p-2 bg-yellow mx-2 my-1">
                    <span role="button" @click="ItemClick(value)" v-html="optiondisplay(value)">
                    </span>
                    <button title="Remove Item" type="button" @click="removeItem(value,'single')" class="ml-5 text-red font-bold">
                        X
                    </button>
                </div>
            </span>
        </div>
        <div class="z-10 p-2 absolute inset-x-0 search-list">
            <ul v-if="items.length && !loading && !hide" class="max-h-96 min-h-96 overflow-y-scroll">
                <li v-for="(item,i) in items" :key="i" class="cursor-pointer p-6 text-black font-work border bg-white">
                    <button class="flex items-center" @focus="hide=false" @click="Selected(item)" type="button">
                        <div>
                            <input disabled type="checkbox" :id="'checkbox-search-item-'+item.id" v-model="item.is_selected">
                            <label class="sr-only" :for="'checkbox-search-item-'+item.id">Option Selected</label>
                        </div>
                        <span v-if="optiondisplay" v-html="optiondisplay(item)">
                        </span>
                        <span v-else>
                            {{item}}
                        </span>
                    </button>
                </li>
            </ul>
        </div>
    </div>
</template>

<script>
import Axios from 'axios'
import Vue from 'vue'
export default {
    name: 'Select',
    data: () => ({
        hide: true,
        loading: false,
        the_id: false,
        search: '',
        search_timeout: false,
        items: [],
    }),
    computed: {
    },
    props: ['id','label','value','multiple','optiondisplay','params','hideSelected','hide_label','multicheck','can_create'],
    methods: {
        CreateItem() {
            if(this.search_timeout) clearTimeout(this.search_timeout)
            this.$emit('item:created',{input: JSON.parse(JSON.stringify(this.search)),uid: this.genId()})
            this.search = ''
            this.items = []
            this.hide = true
        },
        ItemClick(item) {
            this.$emit('item:clicked',{item:item})
        },
        removeItem(item,index){
            this.$emit('item:removed',{item:item,index:index})
        },
        CloseSearch() {
            this.hide = true
            this.search = ''
            this.items = []
        },
        Selected(item) {
            if(!this.multicheck) {
                this.hide = true
                this.search = ''
                this.items = []
            }
            if(item.is_selected) {
                Vue.set(item,'is_selected',false)
                let i = this.value.findIndex((object)=>{return object.id == item.id})
                this.removeItem(item,i)
                return
            }else{
                Vue.set(item,'is_selected',true)
            }
            this.$emit('item:selected',{item:item})
        },
        doSearch() {
            console.log(this.search);
            this.loading = true;
            let that = this;
            Axios.post(that.params.action,{
                search: that.search,
                params: that.params,
            }).then(re=>{
                that.items = re.data.items;
                that.parseItems()
                that.loading = false;
            }).catch(err=>{
                console.log(err);
                that.loading = false;
            });
        },
        parseItems() {
            for(let i in this.items) {
                if(this.value && this.value.find(item=>{return item.id == this.items[i].id})){
                    this.items[i].is_selected = true
                }
            }
        },
        maybeSearch() {
            if(this.search_timeout) clearTimeout(this.search_timeout);
            let that = this;
            if(!that.search || !that.search.length){
                that.items = [];
                return;
            }
            this.search_timeout = setTimeout(function(){ that.doSearch() }, 500);
        },
        emitInput(e) {
            this.$emit('input',e.target.value);
        },
        genId() {
            return Math.random().toString(36).substring(7);
        },
    },
    watch: {
    },
    created() {
        if(!this.id) this.the_id = this.genId();
    },
    beforeDestroy() {
    },
    mounted() {
    },
    components: {
    },
}
</script>
<style scoped>
.search-list {
    top: 90px;
}
</style>
