<template>
<div>
    <TableTemplate @item:checked="ItemChecked" @item:unchecked="ItemUnchecked" :dark="false" :multiselect="multiple" :candelete="false" canview="/tickets/" :columns="columns" :items="objects"></TableTemplate>
</div>
</template>

<script>
export default {
    name: 'TicketsList',
    data: () => ({
        columns:[
            {
                id:'id',
                label:'ID',
            },
            {
                id:'title',
                label:'Title',
            },
            {
                id:'status',
                label:'Status',
            },
        ],
    }),
    computed: {
    },
    props: ['objects','multiple'],
    methods: {
        ItemChecked(item) {
            this.$emit('item:selected',item)
        },
        ItemUnchecked(item) {
            this.$emit('item:removed',item)
        },
    },
    watch: {
    },
    created() {
    },
    beforeDestroy() {
    },
    mounted() {
    },
    components: {
    },
}
</script>
<style scoped>
</style>
