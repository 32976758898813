<template>
<div class="flex flex-col" >
    <div class="text-md">
        <TextInput type="text" label="Field Name" v-model="field_data.name" ></TextInput>
    </div>
    <div class="text-md">
        <SelectInput label="Field Type" v-model="field_data.type" :options="FieldTypes"></SelectInput>
    </div>
    <TextInput type="text" label="" v-model="field_data.value"></TextInput>
</div>
</template>

<script>
import Mixin from './mixin'
export default {
    name: 'TextField',
    data: () => ({
    }),
    computed: {
    },
    mixins: [Mixin],
    methods: {
    },
    created() {
    },
}
</script>
<style scoped>
</style>
