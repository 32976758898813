import Rollbar from 'rollbar';
import Vue from 'vue';
import Axios from 'axios';
import Notifications from 'vue-notification';
import vueCountryRegionSelect from 'vue-country-region-select';
import vClickOutside from 'v-click-outside';
import JsonEditor from 'vue-json-edit';
import './assets/css/theme.css';
import store from './store';
import router from './router';
import FormTemplate from './components/Form.vue';
import TextInput from './components/inputs/Text.vue';
import SelectInput from './components/inputs/Select.vue';
import SearchSelectInput from './components/inputs/SearchSelect.vue';
import ButtonTemplate from './components/Button.vue';
import LinkTemplate from './components/Link.vue';
import TableTemplate from './components/dashboard/Table.vue';
import Notes from './components/dashboard/Notes.vue';
import Comments from './components/dashboard/Comments.vue';
import Attachments from './components/dashboard/Attachments.vue';
import Search from './components/dashboard/Search.vue';
import RadioGroup from './components/inputs/RadioGroup.vue';
import Radio from './components/inputs/Radio.vue';
import Pagination from './components/Pagination.vue';
import Collapsable from './components/Collapsable.vue';
import Checkbox from './components/inputs/Checkbox.vue';
import Wysiwyg from './components/inputs/Wysiwyg.vue';
import SlideoutTabs from './components/dashboard/SlideoutTabs.vue';
import Dropdown from './components/dashboard/Dropdown.vue';
import DatePicker from './components/inputs/DatePicker.vue';
import SuccessCriteria from './components/dashboard/SuccessCriteria.vue';
import Techniques from './components/dashboard/Techniques.vue';
import Recommendations from './components/dashboard/Recommendations.vue';
import ObjectLinks from './components/dashboard/ObjectLinks.vue';
import CustomFields from './components/dashboard/custom_fields/CustomFields.vue';
import Requests from './classes/Requests';
import './components/lists';
import App from './App.vue';
// import PushClass from './classes/Push'
import './registerServiceWorker';

Vue.use(JsonEditor);
Vue.use(vueCountryRegionSelect);
Vue.use(vClickOutside);
Vue.component('TextInput', TextInput);
Vue.component('SelectInput', SelectInput);
Vue.component('SearchSelectInput', SearchSelectInput);
Vue.component('FormTemplate', FormTemplate);
Vue.component('ButtonTemplate', ButtonTemplate);
Vue.component('LinkTemplate', LinkTemplate);
Vue.component('TableTemplate', TableTemplate);
Vue.component('RadioGroup', RadioGroup);
Vue.component('Radio', Radio);
Vue.component('Pagination', Pagination);
Vue.component('Notes', Notes);
Vue.component('Comments', Comments);
Vue.component('Checkbox', Checkbox);
Vue.component('Collapsable', Collapsable);
Vue.component('Wysiwyg', Wysiwyg);
Vue.component('DatePicker', DatePicker);
Vue.component('Attachments', Attachments);
Vue.component('SlideoutTabs', SlideoutTabs);
Vue.component('Dropdown', Dropdown);
Vue.component('Search', Search);
Vue.component('SuccessCriteria', SuccessCriteria);
Vue.component('Techniques', Techniques);
Vue.component('Recommendations', Recommendations);
Vue.component('ObjectLinks', ObjectLinks);
Vue.component('CustomFields', CustomFields);
Vue.use(Notifications);

Vue.config.productionTip = false;
Vue.prototype.$requests = new Requests();
// Vue.prototype.$push = new PushClass()
Vue.prototype.$http = Axios;
Vue.prototype.$http.defaults.headers.common.Accept = 'application/json';
Vue.prototype.$http.defaults.withCredentials = true;
let API = 'https://adminapi.onlineada.com';
if (window.location.hostname === 'admin-alex.devproxy.onlineada.com') {
  API = 'https://adminapi-alex.devproxy.onlineada.com/';
  store.commit('data/setState', { key: 'dashboard', value: 'https://dashboard-alex.devproxy.onlineada.com/' });
} else if (window.location.hostname === 'admin-ross.devproxy.onlineada.com') {
  API = 'https://adminapi-ross.devproxy.onlineada.com/';
  store.commit('data/setState', { key: 'dashboard', value: 'https://dashboard-ross.devproxy.onlineada.com/' });
} else if (window.location.hostname === 'admin3.devproxy.onlineada.com') {
  API = 'https://adminapi3.devproxy.onlineada.com/';
  store.commit('data/setState', { key: 'dashboard', value: 'https://dashboard3.devproxy.onlineada.com/' });
} else {
  store.commit('data/setState', { key: 'dashboard', value: 'https://dashboard.onlineada.com' });
}
store.commit('data/setState', { key: 'baseUrl', value: API });
Vue.prototype.$http.defaults.baseURL = API;

Vue.prototype.$rollbar = new Rollbar({
  accessToken: 'd274bf35407d43eab4e590f98de78c3a',
  captureUncaught: true,
  captureUnhandledRejections: true,
});

Vue.config.errorHandler = (err, vm, info) => {
  vm.$rollbar.error(err);
  throw err; // rethrow
};
async function Start() {
  await Axios.get('/sanctum/csrf-cookie').catch((err) => {
    console.error(err);
  });
  const userResponse = await Axios.get('/auth/user').catch((err) => {
    console.error(err);
  });
  if (userResponse && userResponse.data && userResponse.data.status === 'OK') {
    store.commit('user/setState', { key: 'user', value: userResponse.data.user });
    store.commit('user/setState', { key: 'roles', value: userResponse.data.roles });
    store.commit('user/setState', { key: 'token', value: userResponse.data.token });
  }
  router.beforeEach((to, from, next) => {
    if (store.state.user.roles.admin) {
      next();
    }
    if (!to.meta.auth && !store.getters['user/isAuthenticated']) next({ name: 'Login' });
    if (store.state.roles && !store.state.roles[to.meta.role]) next({ name: 'Dashboard' });
    else next();
  });
  new Vue({
    store,
    router,
    render: (h) => h(App),
  }).$mount('#app');
  Notification.requestPermission().then((result) => {
    if (result === 'granted') {
      store.commit('data/setState', { key: 'allow_notify', value: true });
    }
  });
}

Start();
