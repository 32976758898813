import Vue from 'vue';
import VueRouter from 'vue-router';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    component: () => import(/* webpackChunkName: "dashboard" */ '../views/Dashboard/Main.vue'),
    children: [
      {
        path: '/',
        name: '',
        component: () => import(/* webpackChunkName: "dashboard" */ '../views/Dashboard/Customers/List.vue'),
        meta: {
          role: 'User',
        },
      },
      {
        path: 'services',
        component: () => import(/* webpackChunkName: "services" */ '../views/Dashboard/Services/Main.vue'),
        meta: {
          role: 'Service',
        },
        children: [
          {
            path: '/',
            name: 'All',
            component: () => import(/* webpackChunkName: "services" */ '../views/Dashboard/Services/List.vue'),
            meta: {
              role: 'Read-Service',
            },
          },
          {
            path: 'create',
            name: 'Create Service',
            component: () => import(/* webpackChunkName: "services" */ '../views/Dashboard/Services/Create.vue'),
            meta: {
              role: 'Create-Service',
            },
          },
          {
            path: ':service_id',
            name: 'View Service',
            component: () => import(/* webpackChunkName: "services" */ '../views/Dashboard/Services/View.vue'),
            meta: {
              role: 'Read-Service',
            },
          },
        ],
      },
      {
        path: 'downloads',
        component: () => import(/* webpackChunkName: "downloads" */ '../views/Dashboard/Downloads/Main.vue'),
        meta: {
          role: 'Download',
        },
        children: [
          {
            path: '/',
            name: 'Downloads',
            component: () => import(/* webpackChunkName: "downloads" */ '../views/Dashboard/Downloads/List.vue'),
            meta: {
              role: 'Read-Download',
            },
          },
          {
            path: 'create',
            name: 'Create Download',
            component: () => import(/* webpackChunkName: "downloads" */ '../views/Dashboard/Downloads/Create.vue'),
            meta: {
              role: 'Create-Download',
            },
          },
          {
            path: ':download_id',
            name: 'View Download',
            component: () => import(/* webpackChunkName: "downloads" */ '../views/Dashboard/Downloads/View.vue'),
            meta: {
              role: 'Read-Download',
            },
          },
        ],
      },
      {
        path: 'coupons',
        component: () => import(/* webpackChunkName: "coupons" */ '../views/Dashboard/Coupons/Main.vue'),
        meta: {
          role: 'Coupon',
        },
        children: [
          {
            path: '/',
            name: 'Coupons',
            component: () => import(/* webpackChunkName: "coupons" */ '../views/Dashboard/Coupons/List.vue'),
            meta: {
              role: 'Read-Coupon',
            },
          },
          {
            path: 'create',
            name: 'Create Coupon',
            component: () => import(/* webpackChunkName: "coupons" */ '../views/Dashboard/Coupons/Create.vue'),
            meta: {
              role: 'Create-Coupon',
            },
          },
          {
            path: ':coupon_id',
            name: 'View Coupon',
            component: () => import(/* webpackChunkName: "coupons" */ '../views/Dashboard/Coupons/View.vue'),
            meta: {
              role: 'Read-Coupon',
            },
          },
        ],
      },
      {
        path: 'comments',
        component: () => import(/* webpackChunkName: "comments" */ '../views/Dashboard/Comments/Main.vue'),
        meta: {
          role: 'Comments',
        },
        children: [
          {
            path: '/',
            name: 'Comments',
            component: () => import(/* webpackChunkName: "comments" */ '../views/Dashboard/Comments/List.vue'),
            meta: {
              role: 'Read-Comments',
            },
          },
          {
            path: ':comment_id',
            name: 'View Comment',
            component: () => import(/* webpackChunkName: "comments" */ '../views/Dashboard/Comments/View.vue'),
            meta: {
              role: 'Read-Comments',
            },
          },
        ],
      },
      {
        path: 'subscriptions',
        component: () => import(/* webpackChunkName: "subscriptions" */ '../views/Dashboard/Subscriptions/Main.vue'),
        meta: {
          role: 'Subscriptions',
        },
        children: [
          {
            path: '/',
            name: 'Subscriptions',
            component: () => import(/* webpackChunkName: "subscriptions" */ '../views/Dashboard/Subscriptions/List.vue'),
            meta: {
              role: 'Read-Subscriptions',
            },
          },
          {
            path: 'create',
            name: 'Create Subscription',
            component: () => import(/* webpackChunkName: "subscriptions" */ '../views/Dashboard/Subscriptions/Create.vue'),
            meta: {
              role: 'Read-Subscriptions',
            },
          },
          {
            path: ':subscription_id',
            name: 'View Subscription',
            component: () => import(/* webpackChunkName: "subscriptions" */ '../views/Dashboard/Subscriptions/View.vue'),
            meta: {
              role: 'Read-Subscriptions',
            },
          },
        ],
      },
      {
        path: 'payments',
        component: () => import(/* webpackChunkName: "orders" */ '../views/Dashboard/Payments/Main.vue'),
        meta: {
          role: 'Payments',
        },
        children: [
          {
            path: '/',
            name: 'Payments',
            component: () => import(/* webpackChunkName: "orders" */ '../views/Dashboard/Payments/View.vue'),
            meta: {
              role: 'Read-Payments',
            },
          },
        ],
      },
      {
        path: 'pap',
        component: () => import(/* webpackChunkName: "orders" */ '../views/Dashboard/PAP/Main.vue'),
        meta: {
          role: 'PAP',
        },
        children: [
          {
            path: '/',
            name: 'PAP',
            component: () => import(/* webpackChunkName: "orders" */ '../views/Dashboard/PAP/View.vue'),
            meta: {
              role: 'Read-PAP',
            },
          },
        ],
      },
      {
        path: 'globalsettings',
        component: () => import(/* webpackChunkName: "orders" */ '../views/Dashboard/GlobalSettings/Main.vue'),
        meta: {
          role: 'GlobalSettings',
        },
        children: [
          {
            path: '/',
            name: 'GlobalSettings',
            component: () => import(/* webpackChunkName: "orders" */ '../views/Dashboard/GlobalSettings/View.vue'),
            meta: {
              role: 'Read-GlobalSettings',
            },
          },
        ],
      },
      {
        path: 'orders',
        component: () => import(/* webpackChunkName: "orders" */ '../views/Dashboard/Orders/Main.vue'),
        meta: {
          role: 'Orders',
        },
        children: [
          {
            path: '/',
            name: 'Orders',
            component: () => import(/* webpackChunkName: "orders" */ '../views/Dashboard/Orders/List.vue'),
            meta: {
              role: 'Read-Orders',
            },
          },
          {
            path: 'create',
            name: 'Create Order',
            component: () => import(/* webpackChunkName: "orders" */ '../views/Dashboard/Orders/Create.vue'),
            meta: {
              role: 'Create-Orders',
            },
          },
          {
            path: ':order_id',
            name: 'View Order',
            component: () => import(/* webpackChunkName: "orders" */ '../views/Dashboard/Orders/View.vue'),
            meta: {
              role: 'Read-Orders',
            },
          },
        ],
      },
      {
        path: 'form-submissions',
        component: () => import(/* webpackChunkName: "form-submissions" */ '../views/Dashboard/FormSubs/Main.vue'),
        meta: {
          role: 'FormSubs',
        },
        children: [
          {
            path: '/',
            name: 'Form Submissions',
            component: () => import(/* webpackChunkName: "form-submissions" */ '../views/Dashboard/FormSubs/List.vue'),
            meta: {
              role: 'Read-FormSubs',
            },
          },
          {
            path: ':form_submission_id',
            name: 'View Form Submission',
            component: () => import(/* webpackChunkName: "form-submissions" */ '../views/Dashboard/FormSubs/View.vue'),
            meta: {
              role: 'Read-FormSubs',
            },
          },
        ],
      },
      {
        path: 'notes',
        component: () => import(/* webpackChunkName: "notes" */ '../views/Dashboard/Notes/Main.vue'),
        meta: {
          role: 'Notes',
        },
        children: [
          {
            path: '/',
            name: 'Notes',
            component: () => import(/* webpackChunkName: "notes" */ '../views/Dashboard/Notes/List.vue'),
            meta: {
              role: 'Read-Notes',
            },
          },
          {
            path: ':note_id',
            name: 'View Note',
            component: () => import(/* webpackChunkName: "notes" */ '../views/Dashboard/Notes/View.vue'),
            meta: {
              role: 'Read-Notes',
            },
          },
        ],
      },
      {
        path: 'products',
        component: () => import(/* webpackChunkName: "products" */ '../views/Dashboard/Products/Main.vue'),
        meta: {
          role: 'Product',
        },
        children: [
          {
            path: '/',
            name: 'Products',
            component: () => import(/* webpackChunkName: "products" */ '../views/Dashboard/Products/List.vue'),
            meta: {
              role: 'Read-Product',
            },
          },
          {
            path: 'create',
            name: 'Create Product',
            component: () => import(/* webpackChunkName: "products" */ '../views/Dashboard/Products/Create.vue'),
            meta: {
              role: 'Create-Product',
            },
          },
          {
            path: ':product_id',
            name: 'View Product',
            component: () => import(/* webpackChunkName: "products" */ '../views/Dashboard/Products/View.vue'),
            meta: {
              role: 'Read-Product',
            },
          },
        ],
      },
      {
        path: 'users',
        component: () => import(/* webpackChunkName: "users" */ '../views/Dashboard/Users/Main.vue'),
        meta: {
          role: 'Users',
        },
        children: [
          {
            path: '/',
            name: 'Users',
            component: () => import(/* webpackChunkName: "users" */ '../views/Dashboard/Users/List.vue'),
            meta: {
              role: 'Read-Users',
            },
          },
          {
            path: 'create',
            name: 'Create User',
            component: () => import(/* webpackChunkName: "users" */ '../views/Dashboard/Users/Create.vue'),
            meta: {
              role: 'Create-Users',
            },
          },
          {
            path: ':user_id',
            name: 'View User',
            component: () => import(/* webpackChunkName: "users" */ '../views/Dashboard/Users/View.vue'),
            meta: {
              role: 'Read-Users',
            },
          },
        ],
      },
      {
        path: 'user-logs',
        component: () => import(/* webpackChunkName: "userlogs" */ '../views/Dashboard/UserLogs/Main.vue'),
        meta: {
          role: 'UserLog',
        },
        children: [
          {
            path: '/',
            name: 'User Logs',
            component: () => import(/* webpackChunkName: "userlogs" */ '../views/Dashboard/UserLogs/List.vue'),
            meta: {
              role: 'Read-UserLog',
            },
          },
          {
            path: 'create',
            name: 'Create User Log',
            component: () => import(/* webpackChunkName: "userlogs" */ '../views/Dashboard/UserLogs/Create.vue'),
            meta: {
              role: 'Create-UserLog',
            },
          },
          {
            path: ':log_id',
            name: 'View User Log',
            component: () => import(/* webpackChunkName: "userlogs" */ '../views/Dashboard/UserLogs/View.vue'),
            meta: {
              role: 'Read-UserLog',
            },
          },
        ],
      },
      {
        path: 'licenses',
        component: () => import(/* webpackChunkName: "licenses" */ '../views/Dashboard/Licenses/Main.vue'),
        meta: {
          role: 'License',
        },
        children: [
          {
            path: '/',
            name: 'Licenses',
            component: () => import(/* webpackChunkName: "licenses" */ '../views/Dashboard/Licenses/List.vue'),
            meta: {
              role: 'Read-License',
            },
          },
          {
            path: 'create',
            name: 'Create License',
            component: () => import(/* webpackChunkName: "licenses" */ '../views/Dashboard/Licenses/Create.vue'),
            meta: {
              role: 'Create-License',
            },
          },
          {
            path: ':license_id',
            name: 'View License',
            component: () => import(/* webpackChunkName: "licenses" */ '../views/Dashboard/Licenses/View.vue'),
            meta: {
              role: 'Read-License',
            },
          },
        ],
      },
      {
        path: 'agencies',
        component: () => import(/* webpackChunkName: "agencies" */ '../views/Dashboard/Agencies/Main.vue'),
        meta: {
          role: 'Agency',
        },
        children: [
          {
            path: '/',
            name: 'Agencies',
            component: () => import(/* webpackChunkName: "agencies" */ '../views/Dashboard/Agencies/List.vue'),
            meta: {
              role: 'Read-Agency',
            },
          },
          {
            path: 'create',
            name: 'Create Agency',
            component: () => import(/* webpackChunkName: "agencies" */ '../views/Dashboard/Agencies/Create.vue'),
            meta: {
              role: 'Create-Agency',
            },
          },
          {
            path: ':agency_id',
            name: 'View Agency',
            component: () => import(/* webpackChunkName: "agencies" */ '../views/Dashboard/Agencies/View.vue'),
            meta: {
              role: 'Read-Agency',
            },
          },
        ],
      },
      {
        path: 'affiliates',
        component: () => import(/* webpackChunkName: "customers" */ '../views/Dashboard/Affiliates/Main.vue'),
        meta: {
          role: 'Affiliate',
        },
        children: [
          {
            path: '/',
            name: 'Affiliates',
            component: () => import(/* webpackChunkName: "customers" */ '../views/Dashboard/Affiliates/List.vue'),
            meta: {
              role: 'Read-Affiliate',
            },
          },
        ],
      },
      {
        path: 'customers',
        component: () => import(/* webpackChunkName: "customers" */ '../views/Dashboard/Customers/Main.vue'),
        meta: {
          role: 'Customer',
        },
        children: [
          {
            path: '/',
            name: 'Customers',
            component: () => import(/* webpackChunkName: "customers" */ '../views/Dashboard/Customers/List.vue'),
            meta: {
              role: 'Read-Customer',
            },
          },
          {
            path: 'create',
            name: 'Create Customer',
            component: () => import(/* webpackChunkName: "customers" */ '../views/Dashboard/Customers/Create.vue'),
            meta: {
              role: 'Create-Customer',
            },
          },
          {
            path: ':customer_id',
            name: 'View Customer',
            component: () => import(/* webpackChunkName: "customers" */ '../views/Dashboard/Customers/View.vue'),
            meta: {
              role: 'Read-Customer',
            },
          },
        ],
      },
      {
        path: 'domains',
        component: () => import(/* webpackChunkName: "domains" */ '../views/Dashboard/Domains/Main.vue'),
        meta: {
          role: 'Domain',
        },
        children: [
          {
            path: '/',
            name: 'All Domains',
            component: () => import(/* webpackChunkName: "domains" */ '../views/Dashboard/Domains/List.vue'),
            meta: {
              role: 'Read-Domain',
            },
          },
          {
            path: 'create',
            name: 'Create Domain',
            component: () => import(/* webpackChunkName: "domains" */ '../views/Dashboard/Domains/Create.vue'),
            meta: {
              role: 'Create-Domain',
            },
          },
          {
            path: ':domain_id',
            name: 'View Domain',
            component: () => import(/* webpackChunkName: "domains" */ '../views/Dashboard/Domains/View.vue'),
            meta: {
              role: 'Read-Domain',
            },
          },
        ],
      },
      {
        path: 'tickets',
        component: () => import(/* webpackChunkName: "tickets" */ '../views/Dashboard/Tickets/Main.vue'),
        meta: {
          role: 'Tickets',
        },
        children: [
          {
            path: '/',
            name: 'All',
            component: () => import(/* webpackChunkName: "tickets" */ '../views/Dashboard/Tickets/List.vue'),
            meta: {
              role: 'Read-Tickets',
            },
          },
          {
            path: 'create',
            name: 'Create Ticket',
            component: () => import(/* webpackChunkName: "tickets" */ '../views/Dashboard/Tickets/Create.vue'),
            meta: {
              role: 'Create-Tickets',
            },
          },
          {
            path: ':ticket_id',
            name: 'View Ticket',
            component: () => import(/* webpackChunkName: "tickets" */ '../views/Dashboard/Tickets/View.vue'),
            meta: {
              role: 'Read-Tickets',
            },
          },
        ],
      },
      {
        path: 'tags',
        component: () => import(/* webpackChunkName: "tags" */ '../views/Dashboard/Tags/Main.vue'),
        meta: {
          role: 'Tags',
        },
        children: [
          {
            path: '/',
            name: 'All',
            component: () => import(/* webpackChunkName: "tags" */ '../views/Dashboard/Tags/List.vue'),
            meta: {
              role: 'Read-Tags',
            },
          },
          {
            path: 'create',
            name: 'Create Tag',
            component: () => import(/* webpackChunkName: "tags" */ '../views/Dashboard/Tags/Create.vue'),
            meta: {
              role: 'Create-Tags',
            },
          },
          {
            path: ':tag_id',
            name: 'View Tag',
            component: () => import(/* webpackChunkName: "tags" */ '../views/Dashboard/Tags/View.vue'),
            meta: {
              role: 'Read-Tags',
            },
          },
        ],
      },
      {
        path: 'roles',
        component: () => import(/* webpackChunkName: "roles" */ '../views/Dashboard/Roles/Main.vue'),
        meta: {
          role: 'Role',
        },
        children: [
          {
            path: '/',
            name: 'All',
            component: () => import(/* webpackChunkName: "roles" */ '../views/Dashboard/Roles/List.vue'),
            meta: {
              role: 'Read-Role',
            },
          },
          {
            path: 'create',
            name: 'Create Role',
            component: () => import(/* webpackChunkName: "roles" */ '../views/Dashboard/Roles/Create.vue'),
            meta: {
              role: 'Create-Role',
            },
          },
          {
            path: ':role_id',
            name: 'View Role',
            component: () => import(/* webpackChunkName: "roles" */ '../views/Dashboard/Roles/View.vue'),
            meta: {
              role: 'Read-Role',
            },
          },
        ],
      },
      {
        path: 'calls',
        component: () => import(/* webpackChunkName: "calls" */ '../views/Dashboard/Calls/Main.vue'),
        meta: {
          role: 'Call',
        },
        children: [
          {
            path: '/',
            name: 'All',
            component: () => import(/* webpackChunkName: "calls" */ '../views/Dashboard/Calls/List.vue'),
            meta: {
              role: 'Read-Call',
            },
          },
          {
            path: 'create',
            name: 'Create Call',
            component: () => import(/* webpackChunkName: "calls" */ '../views/Dashboard/Calls/Create.vue'),
            meta: {
              role: 'Create-Call',
            },
          },
          {
            path: ':call_id',
            name: 'View Call',
            component: () => import(/* webpackChunkName: "calls" */ '../views/Dashboard/Calls/View.vue'),
            meta: {
              role: 'Read-Call',
            },
          },
        ],
      },
      {
        path: 'emails',
        component: () => import(/* webpackChunkName: "emails" */ '../views/Dashboard/Emails/Main.vue'),
        meta: {
          role: 'Email',
        },
        children: [
          {
            path: '/',
            name: 'All',
            component: () => import(/* webpackChunkName: "emails" */ '../views/Dashboard/Emails/List.vue'),
            meta: {
              role: 'Read-Email',
            },
          },
          {
            path: 'create',
            name: 'Create Email',
            component: () => import(/* webpackChunkName: "emails" */ '../views/Dashboard/Emails/Create.vue'),
            meta: {
              role: 'Create-Email',
            },
          },
          {
            path: ':email_id',
            name: 'View Email',
            component: () => import(/* webpackChunkName: "emails" */ '../views/Dashboard/Emails/View.vue'),
            meta: {
              role: 'Read-Email',
            },
          },
        ],
      },
      {
        path: 'profile',
        component: () => import(/* webpackChunkName: "profile" */ '../views/Dashboard/Profile/Main.vue'),
        meta: {
          role: 'User',
        },
        children: [
          {
            path: '/',
            name: 'Profile',
            component: () => import(/* webpackChunkName: "profile" */ '../views/Dashboard/Profile/View.vue'),
            meta: {
              role: 'User',
            },
          },
        ],
      },
      {
        path: 'reports',
        component: () => import(/* webpackChunkName: "reports" */ '../views/Dashboard/Reports/Main.vue'),
        meta: {
          role: 'Reports',
        },
        children: [
          {
            path: '/',
            name: 'Reports',
            component: () => import(/* webpackChunkName: "reports" */ '../views/Dashboard/Reports/List.vue'),
            meta: {
              role: 'Reports',
            },
          },
          {
            path: 'create',
            name: 'Create Report',
            component: () => import(/* webpackChunkName: "reports" */ '../views/Dashboard/Reports/Create.vue'),
            meta: {
              role: 'Reports',
            },
          },
          {
            path: ':report_id',
            name: 'View Report',
            component: () => import(/* webpackChunkName: "reports" */ '../views/Dashboard/Reports/View.vue'),
            meta: {
              role: 'Reports',
            },
          },
        ],
      },
      {
        path: 'successcriteria',
        component: () => import(/* webpackChunkName: "settings" */ '../views/Dashboard/Settings/Main.vue'),
        meta: {
          role: 'SuccessCriteria',
        },
        children: [
          {
            path: '/',
            name: 'Success Criteria',
            component: () => import(/* webpackChunkName: "settings" */ '../views/Dashboard/SuccessCriteria/View.vue'),
            meta: {
              role: 'SuccessCriteria',
            },
          },
        ],
      },
      {
        path: 'settings',
        component: () => import(/* webpackChunkName: "settings" */ '../views/Dashboard/Settings/Main.vue'),
        meta: {
          role: 'Settings',
        },
        children: [
          {
            path: '/',
            name: 'General Settings',
            component: () => import(/* webpackChunkName: "settings" */ '../views/Dashboard/Settings/View.vue'),
            meta: {
              role: 'Settings',
            },
          },
        ],
      },
    ],
  },
  {
    path: '/login',
    name: 'Login',
    meta: {
      auth: true,
    },
    component: () => import(/* webpackChunkName: "auth" */ '../views/Login.vue'),
  },
  {
    path: '/lifemuch',
    name: 'Forgot My Life',
    meta: {
      auth: true,
    },
    component: () => import(/* webpackChunkName: "auth" */ '../views/Forgot.vue'),
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

export default router;
