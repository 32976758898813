<template>
<div class="my-2">
    <h2 class="text-red text-md">Comments</h2>
    <div v-if="create_comment.active" class="mt-4">
        <div class="border border-red my-2 shadow-lg p-6">
            <ButtonTemplate :on_click="Back" class="my-2" type="button">
                Back
            </ButtonTemplate>
            <div class="h-full w-full overflow-auto	">
                <wysiwyg v-model="create_comment.content" />
            </div>
            <Attachments :model="{'type':'Comment',id:false}" v-model="create_comment.attachments"></Attachments>
            <ButtonTemplate :on_click="Save" class="my-2" type="button">
                Save
            </ButtonTemplate>
        </div>
    </div>
    <div v-if="edit_comment" class="mt-4">
        <div class="border border-red my-2 shadow-lg p-6">
            <ButtonTemplate :on_click="Back" class="my-2" type="button">
                Back
            </ButtonTemplate>
            <div class="h-full w-full overflow-auto	">
                <wysiwyg v-model="edit_comment.content" />
            </div>
            <Attachments :hide_add="false" :model="{'type':'Comment',id:edit_comment.id}" v-model="edit_comment.attachments"></Attachments>
            <ButtonTemplate :on_click="Update" class="my-2" type="button">
                Save
            </ButtonTemplate>
            <ButtonTemplate btn_style="light" :on_click="MaybeDelete" class="my-2 ml-10" type="button">
                Delete
            </ButtonTemplate>
        </div>
    </div>
    <div v-if="!edit_comment && !create_comment.active" class="mt-4">
        <ButtonTemplate :on_click="New" class="my-2" type="button">
            Add Comment
        </ButtonTemplate>
        <div class="border border-red my-2 shadow-lg p-6" v-for="(comment,i) in comments" :key="i">
            <!--<div class="h-full w-full overflow-auto	" v-html="comment.content"></div>-->
            <div class="h-full w-full overflow-auto	">
                <wysiwyg :disabled="true" v-model="comment.content" />
            </div>
            <Attachments v-show="comment.attachments && comment.attachments.length" :hide_add="true" :model="{'type':'Comment',id:comment.id}" v-model="comment.attachments"></Attachments>
            <ButtonTemplate :on_click="Edit" :click_param="comment" class="my-2" type="button">
                Edit
            </ButtonTemplate>
            <div v-if="comment.created_by" class="h-full w-full">
                <small>
                    Created: {{getDate(comment.created_at)}} By {{comment.created_by.email}}
                </small>
            </div>
            <div class="h-full w-full">
                <small>
                    Last Updated: {{getDate(comment.updated_at)}}
                </small>
            </div>
        </div>
    </div>
</div>
</template>

<script>
import Mixin from '../../mixins/Comments'
import moment from 'moment'
export default {
    name: 'Comments',
    data: () => ({
    }),
    computed: {
    },
    props: [],
    mixins: [Mixin],
    methods: {
        getDate(datetime) {
            return moment(datetime).format('MMMM Do YYYY');
        },
    },
    watch: {
    },
    created() {
    },
    beforeDestroy() {
    },
    mounted() {
    },
    components: {
    },
}
</script>
<style scoped>
</style>
