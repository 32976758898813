<template>
<div role="button" tabindex="0" @click="ToggleOpen" :class="[{'open': open,'flex items-center':!open},'collapsable-wrapper border border-yellow p-6']"> 
    <div :id="the_id" class="collapsable-inner flex items-center font-bold flex w-full justify-between">
        <div class="">
            {{label}}
        </div>
        <div class="">
            <i v-if="open" class="fas fa-chevron-up"></i>
            <i v-else class="fas fa-chevron-down"></i>
        </div>
    </div>
    <div v-if="open" class="collapsable-inner">
        <slot></slot>
    </div>   
</div>
</template>

<script>
export default {
    name: 'Collapsable',
    data: () => ({
        the_id: false,
        open: false,
    }),
    computed: {
    },
    props: ['label'],
    methods: {
        ToggleOpen(evt) {
            if(evt.target.id && evt.target.id == this.the_id) this.open = !this.open
        },
        generateID() {
            let s4 = () => {
                return Math.floor((1 + Math.random()) * 0x10000)
                    .toString(16)
                    .substring(1);
            }
            return s4() + s4() + '-' + s4()
        },
    },
    watch: {
    },
    created() {
        this.the_id = this.generateID()
    },
    beforeDestroy() {
    },
    mounted() {
    },
    components: {
    },
}
</script>
<style scoped>
.collapsable-wrapper {
    height: 50px;
    overflow: hidden;
    transition: .4s height ease-in-out;
}
.collapsable-wrapper.open {
    height: max-content;
    overflow: hidden;
}
</style>
