import Vue from 'vue';

const getDefaultState = () => ({
  baseUrl: false,
  dashboard: false,
  allow_notify: false,
});
export default {
  namespaced: true,
  state: {
    baseUrl: false,
    dashboard: false,
    allow_notify: false,
  },
  mutations: {
    setState(state, payload) {
      Vue.set(state, payload.key, payload.value);
    },
    resetState(state) {
      Object.assign(state, getDefaultState());
    },
  },
  actions: {
    resetState({ commit }) {
      commit('resetState');
    },
  },
  getters: {
  },
};
