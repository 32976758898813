<template>
    <div class="input-wrapper p-2 flex flex-col">
        <label class="text-normal text-open mb-2" :for="the_id">Role Type</label>
        <slot></slot>
    </div>
</template>

<script>

export default {
    name: 'RadioGroup',
    data: () => ({
        the_id: false,
    }),
    computed: {
    },
    props: [],
    methods: {
        genId() {
            return Math.random().toString(36).substring(7);
        },
    },
    watch: {
    },
    created() {
        if(!this.id) this.the_id = this.genId();
    },
    beforeDestroy() {
    },
    mounted() {
    },
    components: {
    },
}
</script>
<style scoped>
</style>
