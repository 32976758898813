<template>
    <div :class="[{'disabled': disabled},'wysiwyg-wrapper']">
        <label class="" :for="the_id">{{label}}</label>
        <vue-editor :disabled="disabled" class="editor" :ref="the_id" :id="the_id" v-model="content" :editor-toolbar="customToolbar"/>
    </div>
</template>

<script>
import { VueEditor } from "vue2-editor/dist/vue2-editor.core.js";
export default {
    name: 'Wysiwyg',
    data: () => ({
        content: '',
        the_id: false,
        customToolbar: [
            [{ 'header': 1 }, { 'header': 2 }],
            ["bold", "italic", "underline","strike"],
            [{ 'script': 'sub'}, { 'script': 'super' }], 
            [{ 'indent': '-1'}, { 'indent': '+1' }],          
            [{ 'direction': 'rtl' }], 
             ['blockquote', 'code-block'],
            [{ list: "ordered" }, { list: "bullet" }],
            [{ 'align': [] }],
        ]
    }),
    computed: {
    },
    props: ['disabled','label','value','placeholder','name','id','required'],
    methods: {
        genId() {
            return Math.random().toString(36).substring(7);
        },
    },
    watch: {
        content(val) {
            this.$emit('input',val);
        },
    },
    created() {
        if(!this.id) this.the_id = this.genId();
        if(this.value) this.content = this.value
        if(this.disabled) this.customToolbar = [[]]
    },
    beforeDestroy() {
    },
    mounted() {
    },
    components: {
        VueEditor,
    },
}
</script>
<style lang="css">
@import "~vue2-editor/dist/vue2-editor.css";

/* Import the Quill styles you want */
@import '~quill/dist/quill.core.css';
@import '~quill/dist/quill.bubble.css';
@import '~quill/dist/quill.snow.css';

.wysiwyg-wrapper .editor {
    background: #ffffff;
    color: #000000;
}
.wysiwyg-wrapper svg{
    width: 18px;
    height: 18px;
}
.wysiwyg-wrapper.disabled .ql-toolbar {
    display:none;
}
.wysiwyg-wrapper.disabled .ql-container {
    border:none;
}
</style>
