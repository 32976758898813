// import axios from 'axios'
import Cookies from 'js-cookie';
import Vue from 'vue';

const getDefaultState = () => ({
  user: false,
  roles: {},
  token: false,
  searches: [],
  supabase: {
    token: false,
  },
});
export default {
  namespaced: true,
  state: {
    user: false,
    roles: {},
    token: false,
    searches: Cookies.get('oada_user_searches'),
    supabase: {
      token: false,
    },
  },
  mutations: {
    setState(state, payload) {
      Vue.set(state, payload.key, payload.value);
    },
    resetState(state) {
      Object.assign(state, getDefaultState());
    },
  },
  actions: {
    resetState({ commit }) {
      commit('resetState');
    },
    Logout({ dispatch }) {
      Cookies.remove('oada_user_nav_history');
      dispatch('resetState');
    },
  },
  getters: {
    isAuthenticated: (state) => state.user,
  },
};
