import Vue from 'vue';

const getDefaultState = () => ({
  open: false,
});
export default {
  namespaced: true,
  state: {
    open: false,
  },
  mutations: {
    setState(state, payload) {
      Vue.set(state, payload.key, payload.value);
    },
    resetState(state) {
      Object.assign(state, getDefaultState());
    },
  },
  actions: {
    resetState({ commit }) {
      commit('resetState');
    },
  },
  getters: {
  },
};
