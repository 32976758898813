<template>
    <router-link :to="to" class="text-white bg-red font-open text-normal font-semibold px-6 py-1">

        <slot></slot>

    </router-link>
</template>

<script>

export default {
    name: 'Link',
    data: () => ({
    }),
    computed: {
    },
    props: ['to'],
    methods: {
    },
    watch: {
    },
    created() {
    },
    beforeDestroy() {
    },
    mounted() {
    },
    components: {
    },
}
</script>
<style scoped>
</style>
