<template>
<div class="w-full">
    <div class="flex">
        <a v-for="(link,i) in links" :key="i" :href="link.path">{{link.label}}</a>
    </div>
</div>
</template>

<script>

export default {
    name: 'ObjectLinks',
    data: () => ({
    }),
    computed: {
    },
    props: ['links'],
    methods: {
    },
}
</script>
<style scoped>
</style>
