<template>
  <div id="app">
    <notifications/>
    <router-view/>
    <Modal v-if="modal_open" class="mt-10"></Modal>
  </div>
</template>

<script>
import Modal from './components/Modal'
export default {
    name: 'MainApp',
    mixins: [],
    data: () => ({
    }),
    computed: {
      modal_open() {
        return this.$store.state.modal.open
      },
    },
    methods: {
    },
    components: {
      Modal
    },
}
</script>
<style>
</style>
